import React, { useEffect } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingXSmall, ParagraphSmall, ParagraphXSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/ui/generic/Checkbox'
import Select from 'components/ui/generic/Select'
import {
  DEFAULT_DISABLE_MINUTES,
  DEFAULT_ENABLE_MINUTES,
  EARLY,
  HOURS,
  LATE,
  MINUTES,
  useControlEarlyLateCheckinContext
} from '../../contexts/control-early-late-checkin-context'
import { OnChangeParams } from 'baseui/select'
import { Flex } from './AppointmentPreferenceForm.styled'

const ControlEarlyAndLateCheckIn = ({
  setAppointmentPreference,
  appointmentPreference,
  disabled
}) => {
  const { t } = useTranslation()
  const {
    state: {
      minutesOptions,
      hoursOptions,
      earlyHoursValue,
      earlyMinutesValue,
      lateHoursValue,
      lateMinutesValue
    },
    actions: {
      setEarlyAndLateHoursAndMinutes,
      setEarlyOrLateHoursAndMinutes,
      setEarlyHours,
      setEarlyMinutes,
      setLateHours,
      setLateMinutes
    }
  } = useControlEarlyLateCheckinContext()

  useEffect(() => {
    if (appointmentPreference?.id) {
      setEarlyAndLateHoursAndMinutes(
        appointmentPreference.preventEarlyCheckinInMinutes,
        appointmentPreference.preventLateCheckinInMinutes
      )
    }
  }, [appointmentPreference?.id])

  const handleTimeSelectChange = (
    { option }: OnChangeParams,
    selectType: string,
    timeType: string
  ) => {
    const isEarly = selectType === EARLY
    const isHours = timeType === HOURS

    const hoursValue = isEarly ? earlyHoursValue : lateHoursValue
    const minutesValue = isEarly ? earlyMinutesValue : lateMinutesValue
    const setHours = isEarly ? setEarlyHours : setLateHours
    const setMinutes = isEarly ? setEarlyMinutes : setLateMinutes

    const preventCheckinInMinutes = isHours
      ? (option as any).id * 60 + minutesValue[0].id
      : hoursValue[0].id * 60 + (option as any).id

    if (isHours) {
      setHours([option] as any)
    } else {
      setMinutes([option] as any)
    }

    setAppointmentPreference({
      ...appointmentPreference,
      [isEarly ? 'preventEarlyCheckinInMinutes' : 'preventLateCheckinInMinutes']:
        preventCheckinInMinutes
    })
  }

  const handleCheckboxClick = (e: any, type: string) => {
    const minutes = e.currentTarget.checked ? DEFAULT_ENABLE_MINUTES : DEFAULT_DISABLE_MINUTES
    setEarlyOrLateHoursAndMinutes(0, minutes, type)
    setAppointmentPreference({
      ...appointmentPreference,
      [type === EARLY ? 'preventEarlyCheckinInMinutes' : 'preventLateCheckinInMinutes']: minutes
    })
  }

  return (
    <FlexGridItem>
      <HeadingXSmall marginTop="0" marginBottom="0">
        {t('AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.Header.Text')}
      </HeadingXSmall>
      <ParagraphXSmall marginTop="0" marginBottom="30px">
        {t('AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.SubHeader.Text')}
      </ParagraphXSmall>
      <FlexGrid marginBottom="30px" flexDirection="row">
        <FlexGridItem maxWidth="50%">
          <FlexGrid display="flex" flexDirection="column">
            <FlexGridItem maxWidth="70px">
              <Checkbox
                checked={earlyHoursValue[0].id + earlyMinutesValue[0].id > 0}
                onChange={e => handleCheckboxClick(e, EARLY)}
                label={t(
                  'AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.Fields.Early.Text'
                )}
              />
            </FlexGridItem>
            <FlexGridItem marginTop="15px">
              <Flex>
                <Select
                  id="hours"
                  placeholder="HH"
                  searchable={false}
                  clearable={false}
                  disabled={disabled || earlyHoursValue[0].id + earlyMinutesValue[0].id === 0}
                  options={hoursOptions}
                  maxWidth="70px"
                  maxWrapperWidth="70px"
                  marginRight="5px"
                  value={earlyHoursValue}
                  onChange={evt => handleTimeSelectChange(evt, EARLY, HOURS)}
                />
                <Select
                  id="minutes"
                  placeholder="MM"
                  searchable={false}
                  clearable={false}
                  disabled={disabled || earlyHoursValue[0].id + earlyMinutesValue[0].id === 0}
                  options={minutesOptions}
                  maxWidth="70px"
                  maxWrapperWidth="70px"
                  value={earlyMinutesValue}
                  onChange={evt => handleTimeSelectChange(evt, EARLY, MINUTES)}
                />
              </Flex>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem maxWidth="50%">
          <FlexGrid display="flex" flexDirection="column">
            <FlexGridItem maxWidth="70px">
              <Checkbox
                checked={lateHoursValue[0].id + lateMinutesValue[0].id > 0}
                onChange={e => handleCheckboxClick(e, LATE)}
                label={t(
                  'AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.Fields.Late.Text'
                )}
              />
            </FlexGridItem>
            <FlexGridItem marginTop="15px">
              <Flex>
                <Select
                  id="hours"
                  placeholder="HH"
                  searchable={false}
                  clearable={false}
                  disabled={disabled || lateHoursValue[0].id + lateMinutesValue[0].id === 0}
                  options={hoursOptions}
                  maxWidth="70px"
                  maxWrapperWidth="70px"
                  marginRight="5px"
                  value={lateHoursValue}
                  onChange={evt => handleTimeSelectChange(evt, LATE, HOURS)}
                />
                <Select
                  id="minutes"
                  placeholder="MM"
                  searchable={false}
                  clearable={false}
                  disabled={disabled || lateHoursValue[0].id + lateMinutesValue[0].id === 0}
                  options={minutesOptions}
                  maxWidth="70px"
                  maxWrapperWidth="70px"
                  value={lateMinutesValue}
                  onChange={evt => handleTimeSelectChange(evt, LATE, MINUTES)}
                />
              </Flex>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
      </FlexGrid>
      <ParagraphSmall marginBottom="0">
        {t('AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.Footer.Text')}
      </ParagraphSmall>
      <ParagraphSmall marginTop="0" marginBottom="0">
        {t('AppointmentPreferences.Form.Fields.ControlEarlyAndLateCheckIn.Footer.Text2')}
      </ParagraphSmall>
    </FlexGridItem>
  )
}

export default ControlEarlyAndLateCheckIn
