import { Answer, Appointment } from 'components/appointments/types'
import { DEFAULT_BACKGROUND_IMG } from 'components/constants/default-media'
import { AccountInfoResponse } from 'components/homepage/signup/SignUp'
import { DRIVER } from 'components/models/User'
import driversCheckinReducer, {
  SET_APPOINTMENTS,
  SET_CHECKIN_FORM,
  SET_OPTION,
  SET_SEARCH,
  SET_SEARCHING,
  SET_SELECTED_APPOINTMENT,
  SET_SHIPPER,
  SET_LOADING,
  SET_SHOW_FACILITY_NOT_FOUND_ERROR,
  SET_SHOW_NO_ACTIVE_FACILITY_ERROR,
  SET_SELECT_FACILITY,
  SET_FACILITY_OPTIONS,
  SET_SELECT_FACILITY_AND_HIDE_SELECT,
  SET_INPUT_SEARCH_LABEL,
  SET_CHECKIN_LABEL_AND_DISABLE_STATUS
} from 'components/reducers/driversCheckin.reducer'
import {
  appointmentService,
  driversCheckinService,
  facilityService,
  shipperService
} from 'components/services'
import { fancyToast } from 'components/utils'
import { SCHEDULED } from 'components/utils/appointment-statuses'
import { SUCCESS_CODE } from 'components/utils/status-codes'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { isUUID } from '../utils/is-uuid'
import i18n from '../../translations/i18n'
import { DateTime } from 'luxon'

export const DRIVER_OPTION = 'driver'
export const EMPLOYEE_OPTION = 'employee'
export const VENDOR_OPTION = 'vendor'
export type CHECKIN_OPTIONS = typeof DRIVER_OPTION | typeof EMPLOYEE_OPTION | typeof VENDOR_OPTION

type Facility = {
  id: string
  name: string
  appointmentPreference: {
    enableDriverSelfCheckin: string
    preCheckinDriverMessage: string
    postCheckinDriverMessage: string
  }
}
export interface DriversCheckinContextProps {
  option: CHECKIN_OPTIONS
  search: string
  searching: boolean
  selectedAppointment: any
  appointments: {
    results: any[]
    total: number
  }
  checkinForm: {
    driverName: string
    driverPhone: string
    trailerNumber: string
    loadingCustomQuestion: boolean
    appointment: Appointment
    checkedIn: boolean
    loading: boolean
    requirePostCheckin: boolean
  }
  searchAppointment: {
    loading: boolean
    selectedFacility: Facility
    facilityOptions: Facility[]
    showFacilityNotFoundError: boolean
    showNoActiveFacilityError: boolean
    hideSelect: boolean
    inputSearchLabel: string
  }
  shipper: AccountInfoResponse
  labelCheckinActionButton: string
  checkinActionButtonShouldBeDisable: boolean
}

export const initialState: DriversCheckinContextProps = {
  option: null,
  search: null,
  searching: null,
  selectedAppointment: null,
  labelCheckinActionButton: i18n.t('DriversCheckin.Search.Actions.Checkin.Text'),
  checkinActionButtonShouldBeDisable: false,
  appointments: {
    results: [],
    total: 0
  },
  checkinForm: {
    driverName: null,
    driverPhone: null,
    trailerNumber: null,
    loadingCustomQuestion: false,
    appointment: null,
    checkedIn: false,
    loading: false,
    requirePostCheckin: false
  },
  searchAppointment: {
    loading: false,
    selectedFacility: null,
    facilityOptions: [],
    showFacilityNotFoundError: false,
    showNoActiveFacilityError: false,
    hideSelect: false,
    inputSearchLabel: undefined
  },
  shipper: {}
}

interface DriversCheckinContextType {
  state: DriversCheckinContextProps
  actions: {
    setOption: (option: CHECKIN_OPTIONS) => void
    setSearch: (value: string) => void
    setSelectedAppointment: (value: string) => void
    clearSearch: () => void
    setCheckin: (value: any) => void
    setCheckinAppointment: (appointment: Appointment) => void
    onPostCheckin: () => Promise<void>
    checkin: () => Promise<void>
    loadSelectedAppointment: (appointmentId: string) => Promise<Appointment>
    setSelectFacility: (facility: Facility) => void
    setSelectFacilityAndHideSelect: (facility: Facility) => void
  }
}

export const DriversCheckinContext = createContext<DriversCheckinContextType | undefined>(undefined)

export const DriversCheckinProvider = ({ children }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(driversCheckinReducer, initialState)
  const { siteCode } = useParams<{ siteCode?: string }>()
  const subdomain = window.location.hostname.split('.')[0]
  const history = useHistory()

  const runSearch = async (): Promise<boolean> => {
    try {
      if (state.search === null || !state.searchAppointment.selectedFacility?.id) {
        return
      }

      setSearching(true)
      const [json, status] = await appointmentService.getAppointmentDriver(
        state.search,
        state.searchAppointment.selectedFacility.id
      )

      if ([200, 204].includes(status)) {
        setAppointments(json)
        if (json?.results.length === 1) {
          setSelectedAppointment(json?.results[0])
        }
      }
      setSearching(false)
    } catch (e) {
      console.log(e)
    }
  }

  const loadSelectedAppointment = async (appointmentId: string): Promise<Appointment> => {
    try {
      if (!appointmentId) {
        return
      }
      const [json, status] = await driversCheckinService.getAnswers(appointmentId)
      if ([200, 204].includes(status)) {
        const requirePostCheckin = somePostCheckinQuestion(json?.answersAttributes)
        setCheckin({
          ...initialState.checkinForm,
          appointment: json,
          requirePostCheckin,
          checkedIn: json?.status !== SCHEDULED
        })

        return json
      }
      return
    } catch (e) {
      console.log(e)
      return
    }
  }

  useDebounce(
    async () => {
      if (state.searching) {
        return
      }
      if (state.search) {
        setSelectedAppointment(null)
        setCheckin({
          ...initialState.checkinForm
        })
        runSearch()
      } else {
        setAppointments(initialState.appointments)
        setSelectedAppointment(null)
      }
    },
    200,
    [state.search]
  )

  useEffect(() => {
    shipperService.getSignUpInfo(subdomain).then(signRes => {
      const { activeBackgroundUrl, background, activeLogoUrl, logo } = signRes

      const finalActiveBackgroundUrl = activeBackgroundUrl
        ? activeBackgroundUrl
        : background?.length
        ? background[background?.length - 1]?.backgroundSecureUrl
        : DEFAULT_BACKGROUND_IMG

      const finalActiveLogoUrl = activeLogoUrl
        ? activeLogoUrl
        : logo?.length
        ? logo[logo?.length - 1]?.logoSecureUrl
        : null

      setShipper({
        ...signRes,
        activeLogoUrl: finalActiveLogoUrl,
        activeBackgroundUrl: finalActiveBackgroundUrl
      })
    })
  }, [])

  useEffect(() => {
    const facilityId = state.searchAppointment?.selectedFacility?.id
    const appointmentTypes = state.shipper?.appointmentTypes

    if (facilityId && appointmentTypes) {
      const appointmentTypesRelatedToSelectedFacility = appointmentTypes.filter(at =>
        at.facilityIds.includes(facilityId)
      )

      const inputSearchLabel =
        appointmentTypesRelatedToSelectedFacility?.map(at => at.name).join(' / ') || undefined

      setInputSearchLabel(inputSearchLabel)
    }
  }, [state.searchAppointment?.selectedFacility, state.shipper])

  useEffect(() => {
    setLoading(true)
    if (siteCode && !isUUID(siteCode)) {
      facilityService
        .getFacilityBySubdomain(siteCode, subdomain, 'driver_self_checkin')
        .then(facility => {
          if (facility.error) {
            setShowFacilityNotFoundError(true)
          } else {
            setSelectFacilityAndHideSelect(facility)
            setShowFacilityNotFoundError(false)
          }
        })
        .catch(() => setShowFacilityNotFoundError(true))
        .finally(() => setLoading(false))
    } else {
      facilityService
        .driverCheckinList(subdomain)
        .then(facilities => {
          if (facilities) {
            if (facilities.length === 1) {
              setSelectFacilityAndHideSelect(facilities[0])
              setShowNoActiveFacilityError(false)
            } else if (facilities.length > 0) {
              setFacilityOptions(facilities)
              setShowNoActiveFacilityError(false)
            } else {
              setShowNoActiveFacilityError(true)
            }
          }
        })
        .catch(() => setShowFacilityNotFoundError(true))
        .finally(() => setLoading(false))
    }
  }, [siteCode])

  const clearSearch = () => {
    setAppointments(initialState.appointments)
    setSelectedAppointment(null)
    setCheckin(initialState.checkinForm)
    setSearch('')
  }

  const checkEarlyOrLateCheckin = appointment => {
    if (!appointment?.arrivalTime) return

    const { preventEarlyCheckinInMinutes, preventLateCheckinInMinutes } =
      state.searchAppointment.selectedFacility.appointmentPreference

    const { arrivalTime } = appointment

    const arrivalDate = DateTime.fromISO(arrivalTime)
    const now = DateTime.now()

    const differenceInMinutes = arrivalDate.diff(now, 'minutes').minutes

    if (
      preventEarlyCheckinInMinutes > 0 &&
      differenceInMinutes > preventEarlyCheckinInMinutes &&
      now < arrivalDate
    ) {
      dispatch({
        type: SET_CHECKIN_LABEL_AND_DISABLE_STATUS,
        payload: {
          labelCheckinActionButton: t('DriversCheckin.Search.Actions.CheckinTooEarly.Text', {
            time: arrivalDate.minus({ minutes: preventEarlyCheckinInMinutes }).toFormat('hh:mm a')
          }),
          checkinActionButtonShouldBeDisable: true
        }
      })
    } else if (
      preventLateCheckinInMinutes > 0 &&
      differenceInMinutes < -preventLateCheckinInMinutes &&
      now > arrivalDate
    ) {
      dispatch({
        type: SET_CHECKIN_LABEL_AND_DISABLE_STATUS,
        payload: {
          labelCheckinActionButton: t('DriversCheckin.Search.Actions.CheckinTooLate.Text', {
            time: arrivalDate.plus({ minutes: preventLateCheckinInMinutes }).toFormat('hh:mm a')
          }),
          checkinActionButtonShouldBeDisable: true
        }
      })
    }
  }

  const somePostCheckinQuestion = (answers: Answer[]) => {
    return answers?.some(
      answer =>
        !answer?.question?.questionPermissionsAttributes?.some(
          permission => permission?.postCheckin && permission?.userRole?.audience === DRIVER
        )
    )
  }

  const setInputSearchLabel = (inputSearchLabel: string) => {
    dispatch({ type: SET_INPUT_SEARCH_LABEL, payload: inputSearchLabel })
  }

  const setShipper = (shipper: AccountInfoResponse) => {
    dispatch({ type: SET_SHIPPER, payload: shipper })
  }

  const setLoading = (loading: boolean) => {
    dispatch({ type: SET_LOADING, payload: loading })
  }

  const setShowFacilityNotFoundError = (error: boolean) => {
    dispatch({ type: SET_SHOW_FACILITY_NOT_FOUND_ERROR, payload: error })
  }

  const setShowNoActiveFacilityError = (error: boolean) => {
    dispatch({ type: SET_SHOW_NO_ACTIVE_FACILITY_ERROR, payload: error })
  }

  const setOption = (option: CHECKIN_OPTIONS) => {
    dispatch({ type: SET_OPTION, payload: option })
  }

  const setSearch = (value: string) => {
    dispatch({ type: SET_SEARCH, payload: value })
  }

  const setSearching = (value: boolean) => {
    dispatch({ type: SET_SEARCHING, payload: value })
  }

  const setAppointments = (appointments: any) => {
    dispatch({ type: SET_APPOINTMENTS, payload: appointments })
  }

  const setSelectedAppointment = (value: string) => {
    checkEarlyOrLateCheckin(value)
    dispatch({ type: SET_SELECTED_APPOINTMENT, payload: value })
  }

  const setSelectFacility = (facility: Facility) => {
    dispatch({ type: SET_SELECT_FACILITY, payload: facility })
  }

  const setSelectFacilityAndHideSelect = (facility: Facility) => {
    dispatch({ type: SET_SELECT_FACILITY_AND_HIDE_SELECT, payload: facility })
  }

  const setFacilityOptions = (facilities: Facility[]) => {
    dispatch({ type: SET_FACILITY_OPTIONS, payload: facilities })
  }

  const setCheckinAppointment = (value: Appointment) => {
    dispatch({
      type: SET_CHECKIN_FORM,
      payload: {
        ...state.checkinForm,
        appointment: value
      }
    })
  }

  const setCheckin = (value: any) => {
    dispatch({ type: SET_CHECKIN_FORM, payload: value })
  }

  const createCheckin = async (): Promise<boolean> => {
    try {
      const [data, status] = await driversCheckinService.checkinAppointment(
        state.checkinForm.appointment,
        {
          ...state.checkinForm
        }
      )

      if (status == 200 || status == 201) {
        clearSearch()
        return true
      } else {
        fancyToast({ error: t('Common.Errors.Default.Text') }, status)
      }
      return false
    } catch (error) {
      console.error(error)
      fancyToast({ error: t('Common.Errors.Default.Text') })
      return false
    }
  }

  const updateAppointment = async (): Promise<boolean> => {
    return await driversCheckinService
      .updateAppointment(
        state.checkinForm.appointment,
        state.checkinForm.appointment.answersAttributes
      )
      .then(async ([json, status]) => {
        if (status === SUCCESS_CODE) {
          return true
        } else {
          fancyToast(json, status)
        }
        return false
      })
      .catch(e => {
        fancyToast({ error: t('Common.Errors.Default.Text') })
        return false
      })
  }

  const checkin = async () => {
    if (state.checkinForm.appointment) {
      let checkedIn = false
      setCheckin({
        ...state.checkinForm,
        loading: true
      })
      const appointmentUpdated = await updateAppointment()
      if (appointmentUpdated) {
        const checkin = await createCheckin()
        if (checkin) {
          fancyToast({ info: t('Common.Info.Saved.Default.Text') }, 201)
          checkedIn = true
        }
      }
      setCheckin({
        ...state.checkinForm,
        loading: false,
        checkedIn
      })
    }
  }

  const onPostCheckin = async () => {
    if (state.checkinForm.appointment) {
      setCheckin({
        ...state.checkinForm,
        loading: true
      })
      const appointmentUpdated = await updateAppointment()
      if (appointmentUpdated) {
        fancyToast({ info: t('Common.Info.Saved.Default.Text') }, 201)
        history.push(`/pass`)
      }
      setCheckin({
        ...state.checkinForm,
        loading: false
      })
    }
  }

  const actions = {
    setOption,
    setSearch,
    setSelectedAppointment,
    clearSearch,
    setCheckin,
    setCheckinAppointment,
    loadSelectedAppointment,
    onPostCheckin,
    checkin,
    setSelectFacility,
    setSelectFacilityAndHideSelect
  }

  return (
    <DriversCheckinContext.Provider value={{ state: { ...state }, actions }}>
      {children}
    </DriversCheckinContext.Provider>
  )
}

export const useDriversCheckinContext = (): DriversCheckinContextType => {
  const context = useContext(DriversCheckinContext)
  if (context === undefined) {
    throw new Error('useDriversCheckinContext must be used within an DriversCheckinProvider')
  }
  return context
}
